import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, map } from 'rxjs';

import { Claims } from '../enums/claims.enum';
import { Roles } from '../enums/roles.enum';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  constructor(private auth: AuthService) {}

  public isAdmin(): Observable<boolean> {
    return this.auth.user$.pipe(
      map(user => {
        if (user) {
          return user[Claims.RoleIdentityClaim].some((role: string) => role === Roles.Administrator);
        }
        return false;
      }),
    );
  }
}
