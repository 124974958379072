import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';

import { UserRoleService } from '../services/user-role.service';

@Injectable({
  providedIn: 'root',
})
export class AdministratorGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private userRoleService: UserRoleService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userRoleService.isAdmin();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userRoleService.isAdmin();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.userRoleService.isAdmin();
  }
}
